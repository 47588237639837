import React, {Component} from 'react';
import "./WhoWeAre.css";
import {Container, Col, Row} from 'reactstrap';
import IG from "./instagram.png";
import SC from "./socialcredit.png";
import Convert from "./convert.png";
class WhoWeAre extends Component {
    render(){
        return (
            <>
            <div id="wwaDiv">
            <br />
            <br />
            <h1 id="whoTitle">who we Are</h1>
            <p id="row2">The Carter Group is an industry leader in digital reach and conversions,
            generating an average of <strong> 1 Billion </strong> impressions monthly.</p>
         <Container>
             <Row id="row">
                 <Col xs="12" md="4"><br /><img src={IG} id="pic"></img><br /><br /><h5>Utilize the #1 Social Platform</h5></Col>
                 <Col xs="12" md="4"><br /><img src={Convert} id="pic"></img><br /><br /><h5>Turn Ads into Conversions</h5></Col>
                 <Col xs="12" md="4"><br /><img src={SC}id="pic" ></img><br /><br /><h5>Gain Buzz and Social Credit</h5></Col>
             </Row>
         </Container>
         <br /> 
         <br />
          </div>
            </>
        );
    }
}
export default WhoWeAre; 