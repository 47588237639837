import React, {Component} from 'react';
import video from './video.mp4';
import "./Video.css";

class Video extends Component {
    render(){
        return(
            <>
            <video src={video} autoPlay loop muted id="videoCSS"></video>
         </>
        );
    }
}
export default Video; 
