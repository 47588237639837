import React, {Component} from 'react';
import "./Clients.css";

class Clients extends Component {
    render(){
        return (
            <>
            <div id="clientBG">
                <br/> <br />
           <h1 id="clientTitle">our clients</h1>
           <br />
           <h3 id="clientTitle">recording Artists</h3>
           <p id="listing">Regardless of the creative medium, The Carter Group can connect your brand with millions of users, generating buzz and social credibility for any campaign. We have expertise working with A-list recording artists, bringing their message to fans world-wide in a matter of moments. Our industry experience allows us to craft and create the perfect campaign for any goal.</p>
           <p id="listing">The Chainsmokers, Danny Ocean, Logic, Megan Trainor, Tove Lo, Austin Mahone, Lil Pump, Jessie J</p>
          <br />
          <h3 id="clientTitle">public figures</h3>
           <p id="listing">The Carter Group has had the pleasure of working with many household names. We aim to deeply understand our client’s needs and concentrate our focus on the unique goals every client has.
 Whether it’s a movie, series premiere, a brand collaboration, or a new business venture, The Carter Group will ensure that each and every campaign will reach the audience it deserves. 
</p>
           <p id="listing">Sylvester Stallone, Hayden Christiansen, Emma Roberts, David Harbour, Max Ehrich, Jean-Claude Van Damme, Gary Vee, Yvonne Strahovski, Ryan Guzman</p>
          <br />
           <h3 id="clientTitle">production studios</h3>
           <p id="listing">Digital media has never been consumed faster. The Carter Group knows how to leverage digital consumption patterns to give you the best reach to your audience. We are results-driven, proven performers. We are proud to deliver the best results to clients such as Netflix,  Hulu, and Atlantic Records. Whether it’s music or film, we know how to get your project the digital exposure and consumer awareness that it merits.</p>
           <p id="listing"> Netflix, Hulu, ABC, Marvel Studios, Vudu Movies, Lionsgate Films, Sunset Studios, Sony, Atlantic Records, Def Jam Records, American Idol, Avengers Endgame, Stranger Things, Game of Thrones: Season 8, Little Italy</p>
           <br />
           <h3 id="clientTitle">consumer products</h3>
           <p id="listing">Leveraging digital media to increase consumer awareness of new and existing products. We have partnered with many direct-to-consumer brands, and we are proud of the reach we have been able to give our clients. Gone are the days of storefront advertising. Allow The Carter Group to bring your product awareness and revenue stream into the digital age.</p>
           <p id="listing">Ivory Ella, Ten Tree, Flawless Beauty</p>
           <br />
           <h3 id="clientTitle">App instAlls</h3>
           <p id="listing">Mobile applications are one of the biggest revenue generators for any kind of business. Whether you are migrating an existing system to mobile, or have created a brand new idea- gain traction, notariety and business through The Carter Group's mobile app download service.
          We facilitated hundreds of thousands of app installations for companies including Calm (IOS 2017 App of the Year), Tik Tok, and The Plug Analytics.</p>
          <p id="listing">Calm, Tik Tok, The Plug Analytics</p>
         <br />
         <br />
         <br />
           </div>
            </>
        );
    }
}
export default Clients; 