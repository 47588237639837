import React, {Component} from 'react';
import "./Footer.css";

class Footer extends Component {
    render(){
        return(
            <div id="footerDiv">
               
                <br /> the cArter group 2019<br />
                built by emily morAnhA
                <br /> <br />
        
            </div>
        );
    }
}
export default Footer; 