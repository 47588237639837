import React, {Component} from 'react';
import "./Services.css";

class Services extends Component {
    render(){
        return (
            <>
            <div id="serviceDiv">
                <br /><br />
           <h1 id="serviceTitle">services</h1>
           <br />
            <h3 id="serviceTitle">follower-driven campAigns</h3>
        <p id="bodyText">
        Gain organically-driven, real followers in record time. 
           <br /> Build your business' social credit. 
           <br />  Campaign goal driven, no monthly fee  
           <br /> Custom content creation </p>
           <br />
           <h3 id="serviceTitle">product visibility</h3>
           <p id="bodyText">
               Effective marketing for products <br />
           Reach up to 1 million+ users daily <br />
           Drive traffic to your website 
           <br /> </p>
           <br />
           <h3 id="serviceTitle">high conversion video</h3>
           <p id="bodyText">
               Engage users with unique video content <br />
           Build trust with quality video content<br />
           Just supply the raw video file, we take care of the rest
           <br /> </p>
           <br />
           <h3 id="serviceTitle">App downloAds</h3>
           <p id="bodyText"> Hundreds of thousands of app installations completed
           <br />  Top 1% finish for Instagram in The Plug Analytics App Installation Competition
            <br /> Hundreds of thousands of app installations for companies including Calm (IOS 2017 App of the Year), <br /> Tik Tok, and The Plug Analytics
            </p> <br /> <br />
           </div>
           </>
        );
    }
}
export default Services; 