import React, {Component} from 'react';
import video from "./video.mp4";
import "./FooterVideo.css";
class FooterVideo extends Component {
    render(){
        return(
            <div id="footVideoDiv">
            <video src={video} autoPlay loop muted id="footervideo"></video>
            </div>
        );
    }
}
export default FooterVideo;