import React, {Component} from 'react';
import "./Contact.css";
import { Button } from 'reactstrap';
class Contact extends Component {
    render(){
        return (
            <>
            <div id="contactDiv">
                <br /><br />
           <h1 id="contactTitle">contAct</h1>
           <h4 id="contactTitle">contAct the cArter group</h4>
           <br />
           <Button id="contactTitle" size="lg" href="mailto:thecartermediagroupcontact@gmail.com">e-mAil us</Button>
          <br /><br /><br /><br />
          </div>
           </>
        );
    }
}
export default Contact; 