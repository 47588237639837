import React, {Component} from 'react';
import './App.css';
import Navigation from './Navigation';
import Video from "./Video";
import WhoWeAre from "./WhoWeAre";
import Services from "./Services";
import Clients from "./Clients";
import Contact from "./Contact";
import Footer from "./Footer";
import FooterVideo from "./FooterVideo";
class Home extends Component {
    render(){
        return (
            <>
         <Navigation />
         <Video />
         <WhoWeAre />
         <Services />
         <Clients />
         <Contact />
         <Footer />
            </>
        );
    }
}
export default Home; 