import React, { useState } from 'react';
import { Collapse, Navbar, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink } from 'reactstrap';
import "./Navigation.css";

const Navigation = (props) => {
  const [collapsed, setCollapsed] = useState(true);
  const toggleNavbar = () => setCollapsed(!collapsed);

  return (
    <div>
      <Navbar color="faded" dark id="redNav">
        <NavbarBrand href="/" className="mr-auto" id="text" id="headline">the cArter group</NavbarBrand>
        <NavbarToggler onClick={toggleNavbar} className="mr-2" />
        <Collapse isOpen={!collapsed} navbar>
          <Nav navbar>
          <NavItem>
              <NavLink href="/" id="text" id="headline">home</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#whoTitle" id="text" id="headline">who we Are</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#serviceTitle" id="text" id="headline">services</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#clientTitle" id="text" id="headline">clients</NavLink>
            </NavItem>
            <NavItem>
              <NavLink href="#contactTitle" id="text" id="headline">contAct</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}

export default Navigation;